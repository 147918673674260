import Icons from "icons/sidebar";
const sideMenuList1 = [
];
const adminMenuSidebar = [
  {
    title: "관리페이지",
    Icon: Icons.DashboardIcon,
    path: "/",
    children: [
      {
        name: "쿠폰관리",
        path: "/manager",
      },
      {
        name: "유저관리",
        path: "/admin/user-list",
      },
      {
        name: "지사 추가",
        path: "/admin/register",
      }
    ]
  }
];

const sideMenuList2 = [
  {
    title: "관리페이지",
    Icon: Icons.DashboardIcon,
    path: "/",
    children: [
      {
        name: "쿠폰관리",
        path: "/manager",
      }
    ]
  }
];
export { sideMenuList1, sideMenuList2, adminMenuSidebar };
