import { Box, styled } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
const StyledBox = styled(Box)(({ ellipsis }) => ({
  ...(ellipsis && {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
}));
export const H1 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={28}
      component="h1"
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const H2 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={22}
      component="h2"
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const H3 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={18}
      component="h3"
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const H4 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={19}
      component="h4"
      fontWeight={700}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const ButtonText = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={15}
      component="h5"
      // lineHeight={1}
      whiteSpace={"nowrap"}
      fontWeight={500}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const H5 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={17}
      component="h5"
      lineHeight={1}
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const H6 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={15.5}
      component="h6"
      fontWeight={500}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const H7 = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={15}
      component="h7"
      fontWeight={500}
      ellipsis={ellipsis ? 1 : 0}
      color="text.secondary"
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const Paragraph = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={16}
      component="p"
      fontWeight={500}
      ellipsis={ellipsis ? 1 : 0}
      color="#808187"
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export function TimerContent({ children, trigger, endEvent }) {
  const [timer, setTimer] = useState(children);

  useEffect(() => {
    let interval;

    if (trigger) {
      if (/^\d+:\d+$/.test(children)) {
        const [minutes, seconds] = children.split(":");
        let remainingTime = parseInt(minutes) * 60 + parseInt(seconds);

        interval = setInterval(() => {
          remainingTime--;

          if (remainingTime <= 0) {
            endEvent()
            clearInterval(interval);
          }

          const updatedMinutes = Math.floor(remainingTime / 60);
          const updatedSeconds = remainingTime % 60;
          setTimer(`${updatedMinutes}:${updatedSeconds.toString().padStart(2, "0")}`);
        }, 1000);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [children, trigger]);

  return (
    <StyledBox
      fontSize={15}
      component="p"
      fontWeight={600}
      ellipsis={0}
      whiteSpace={"nowrap"}
      // color="text.disabled"
      color="#808187"
      className={clsx({
        [""]: true,
      })}
    >
      {timer == "0:00" || timer == "0:0" ? "3:00" : timer}
    </StyledBox>
    );
}
export const TableContent = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={15}
      component="p"
      fontWeight={600}
      ellipsis={ellipsis ? 1 : 0}
      whiteSpace={"nowrap"}
      // color="text.disabled"
      color="#808187"
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const Small = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={14.5}
      component="small"
      fontWeight={500}
      lineHeight={1.6}
      color={"#808187"}
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const Span = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={14.5}
      fontWeight={600}
      component="span"
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
export const Tiny = (props) => {
  const { ellipsis, children, className, ...others } = props;
  return (
    <StyledBox
      fontSize={14}
      fontWeight={500}
      lineHeight={1.65}
      color={"#808187"}
      whiteSpace={"nowrap"}
      component="p"
      ellipsis={ellipsis ? 1 : 0}
      className={clsx({
        [className || ""]: true,
      })}
      {...others}
    >
      {children}
    </StyledBox>
  );
};
