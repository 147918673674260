import {
  AppBar,
  Box,
  ClickAwayListener,
  IconButton,
  styled,
  Toolbar,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import SearchIcon from "icons/SearchIcon";
import { useContext, useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import BigSearchBar from "./BigSearchBar";
import { ButtonText, H5, H6 } from "components/Typography";
import { NavLink } from "react-router-dom";
import logo from "assets/sugar_logo.png";
import globalcss from "styles/global.css";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import { Cookies } from "react-cookie";
import Request from "utils/request";
import CompleteModal from "pages/complete-modal";
import { useSelector } from "react-redux";

// ------------------------------------------------
// custom styled components
const StyledToolBar = styled(Toolbar)(() => ({
  // padding: { xs: "0rem 2rem", sm: "0rem 7rem" },
  alignItems: "center",
  justifyContent: "space-between",
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const ToggleIcon = styled(Box)(({ theme, width }) => ({
  height: 3,
  margin: "5px",
  marginLeft: 0,
  width: width || 25,
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.darkBlue,
}));

const PanelContainer = styled(Box)({
  // display: "none",
  position: "absolute",
  top: "100%",
  left: 0,
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  padding: "40px 80px",
  borderRadius: "0px",
  borderTop: "1px solid #E1E1E1",
  borderBottom: "1px solid #E1E1E1",
  zIndex: 10,
});

const Row = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  // marginBottom: "8px",
});

const Column = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  // marginBottom: "8px",
  // borderLeft: "2px solid #E1E1E1",
  paddingLeft: 24,
  paddingTop: 0,
});

const ColumnItem = styled(Box)({
  // width: "calc(25% - 8px)",
  fontSize: 16,
  fontWeight: 500,
  color: "#151515",
  padding: "4px",
  borderRadius: "4px",
  whiteSpace: "nowrap",
});

const cookies = new Cookies();

const DashboardHeader = (props) => {
  const token = useSelector((state) => state.Auth.token);
  const account = useSelector((state) => state.Auth.account);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [isAuth, setIsAuth] = useState(false);


  const [showPanel, setShowPanel] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { setShowMobileSideBar } = props;
  const [openSearchBar, setSearchBar] = useState(false);
  const { settings, saveSettings } = useContext(SettingsContext);
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));

  const { setNodeRef, listeners, transform } = useDraggable({
    id: "draggable",
  });

  // custom styled components
  const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
    zIndex: 11,
    boxShadow: "none",

    padding: isTablet ? "0.5rem 2rem" : "0rem 3rem",
    boxShadow: "0px 12px 12px rgba(0, 0, 0, 0.02)",
    width: `calc(100% - ${isTablet ? "0px" : "260px"})`,
    marginLeft: isTablet ? "0px" : "260px",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #E1E1E1",
    // marginBottom: "1.5rem",
    color: theme.palette.text.primary,
  }));

  const [show, setShow] = useState(false);

  return (
    <DashboardHeaderRoot position="sticky">
      <CompleteModal title={"로그아웃 완료"} description={""} action={"window.location.href = '/'"} showModal={show} setShowModal={setShow}/>

      {isTablet && (
        <StyledToolBar>
          <Box
            mb={0.5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {downMd && (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={setShowMobileSideBar}
              >
                <ToggleIcon />
                <ToggleIcon width={18} />
                <ToggleIcon width={9} />
              </Box>
            )}
          </Box>

          <ClickAwayListener onClickAway={() => setSearchBar(false)}>
            <Box>
              <SearchBar
                open={openSearchBar}
                handleClose={() => setSearchBar(false)}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // backgroundColor: "#000000",
                  // width: '100%'
                }}
              >
                {
                  account ? (
                    null
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        height: "28px",
                        fontSize: 14.5,
                        borderRadius: 2,
                        maxWidth: "70px",
                        minWidth: "70px",
                        borderRadius: 2,
                        backgroundColor: "primary.darkBlue",
                        "&:hover": {
                          backgroundColor: "primary.darkBlueHover",
                        },
                      }}
                    >
                      <H6
                        fontWeight={600}
                        onClick={() => window.location.href = "/"}
                        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      >
                        로그인
                      </H6>
                    </Button>
                  )
                }
              </Box>
            </Box>
          </ClickAwayListener>
        </StyledToolBar>
      )}
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
