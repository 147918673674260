
import axios from "axios";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export default class Request {
    
    constructor(authorization, ip) {
        this.authorization = authorization;
        this.headers = {
            "Content-Type": "application/json",
            "authorization": `Bearer ${authorization}`,
            "ip": ip || ""
        };
    }
    
    setIp(ip) {
        this.headers = {
            "Content-Type": "application/json",
            "authorization": `Bearer ${this.authorization}`,
            "ip": ip
        };
    }

    get(url) {
        return axios.get(url, {headers: this.headers})
    }
    
    post(url, data) {
        return axios.post(url, data, {headers: this.headers})
    }
    
    delete(url) {
        return axios.delete(url, {headers: this.headers})
    }

    patch(url, data) {
        return axios.patch(url, data, {headers: this.headers})
    }
    
    post2(url, data, headers) {
        return axios.post(url, data, headers)
    }
}