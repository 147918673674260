import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment, useState } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

const DashboardLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showSideBar, setShowSideBar] = useState(true);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const customStyle = {
    width: `calc(100% - ${showSideBar ? "260px" : "0px"})`,
    marginLeft: showSideBar ? "260px" : "0px",
  };

  return (
    <Fragment>
      <DashboardSidebar
        showSideBar={showSideBar}
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={() => setShowMobileSideBar(false)}
      />
      <DashboardHeader
        setShowSideBar={() => setShowSideBar((state) => !state)}
        showSideBar={showSideBar}
        setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
      />

      <LayoutBodyWrapper sx={customStyle}>
        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
