const LOGIN = 'login';
const LOGOUT = 'logout';
const AuthInitialState = {
  token: null,
  account: null
}

export const setLogin = (token, account) => ({
  type: LOGIN,
  token,
  account
})

export const Logout = () => ({
  type: LOGOUT,
})

export const AuthReducer = (state = AuthInitialState, action) => {
  switch(action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.token,
        account: action.account
      }
    case LOGOUT:
      return {
        ...state,
        token: null,
        account: null
      }
    default:
      return state;
  }
}