/* eslint-disable array-callback-return */
import {
  Box,
  List,
  ListItemButton,
  styled,
  useMediaQuery,
  Collapse,
  Divider,
  Button,
  MenuItem,
  Grid,
} from "@mui/material";
import Scrollbar from "components/ScrollBar";
import { ButtonText, H5, H6, Small, Tiny } from "components/Typography";
import LayoutDrawer from "layouts/layout-parts/LayoutDrawer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import FlexBox from "../../components/flexbox/FlexBox";
import { lightTheme } from "../../constants";
import { adminMenuSidebar, sideMenuList1, sideMenuList2 } from "../layout-parts/navList";
import AccordionMenu from "./AccordionMenu";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Icons from "icons/sidebar";
import logo from "assets/sugar_logo.png";
import img1 from "assets/img1.png";
import { useTheme } from "@emotion/react";
import Request from "utils/request";
import { Cookies } from "react-cookie";
import CompleteModal from "pages/complete-modal";
import {useSelector} from "react-redux";
import { Logout, setLogin } from "reducer/authReducer";
import { useDispatch } from "react-redux";

const cookies = new Cookies();
const saasMenuItems = [
  { name: "설계사 커뮤니티", path: "" },
  { name: "고객 커뮤니티", path: "" },
];

// --------------------------------------------------------------
// custom styled components
const SidebarWrapper = styled(Box)(({ theme, show }) => ({
  left: show ? 0 : -260,
  width: 260,
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "left 0.3s ease",
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.background.paper,
}));
const StyledLogo = styled(Box)(() => ({
  marginBottom: "2rem",
  marginLeft: "-2px",
}));
const StyledListItemButton = styled(ListItemButton)(({ active, theme }) => ({
  padding: 12,
  marginBottom: 8,
  color: active ? "#ffffff" : "inherit",

  backgroundColor: active ? theme.palette.primary.darkBlue : "#ffffff",

  borderRadius: 10,
  "&:hover": {
    backgroundColor: active ? theme.palette.primary.darkBlue : "#ffffff",
  },
  "& .MuiSvgIcon-root": {
    color: active ? "#ffffff" : theme.palette.primary.drakBlue,
  },
}));
const Dot = styled(Box)(() => ({
  width: 5,
  height: 5,
  marginRight: 10,
  borderRadius: "50%",
}));
const SubMenuItem = styled(FlexBox)(({ theme, active }) => ({
  cursor: "pointer",
  overflow: "hidden",
  alignItems: "center",
  position: "relative",
  padding: "0.6rem 1rem",
  borderRadius: 10,

  backgroundColor: active
    ? lightTheme(theme)
      ? "#F6F6F6"
      : theme.palette.divider
    : "transparent",
  "& div": {
    backgroundColor: active
      ? theme.palette.primary.darkBlue
      : theme.palette.grey[600],
  },
  "& small": {
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
  },
  "&:hover": {
    backgroundColor: lightTheme(theme) ? "#F6F6F6" : theme.palette.divider,
    "& div": {
      backgroundColor: theme.palette.primary.main,
    },
    "& small": {
      color: theme.palette.primary.main,
    },
    "&::before": {
      opacity: 1,
    },
  },
  "&::before": {
    left: 0,
    width: 2,
    content: '""',
    height: "100%",
    borderRadius: 5,
    position: "absolute",
    opacity: active ? 1 : 0,
    transition: "opacity 0.3s ease",
    // backgroundColor: theme.palette.primary.main,
  },
})); // root component

const DashboardSidebar = (props) => {
  const token = useSelector((state) => state.Auth.token);
  const account = useSelector((state) => state.Auth.account);
  const dispatch = useDispatch();
  
  const [show, setShow] = useState(false)
  const theme = useTheme();
  const { showSideBar, showMobileSideBar, closeMobileSideBar } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState("");
  
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200)); // active accordion
  const [saasMenuOpen, setSaasMenuOpen] = useState(false);
  const [saasMenuExpanded, setSaasMenuExpanded] = useState(false);

  const handleSaasMenuClick = (path) => {
    setSaasMenuOpen((prevOpen) => !prevOpen);
    // if (path) navigate(path);
  };

  const handleSaasArrowClick = () => {
    setSaasMenuOpen((prevOpen) => !prevOpen);
  };

  const activeAccordion = () => {
    sideMenuList1.forEach((list) => {
      if (list.children && list.children.length > 0) {
        const findItem = list.children.find((item) => item.path === pathname);
        if (findItem) setExpanded(list.title);
      }
    });
  };

  useEffect(activeAccordion, [pathname]);

  const handleAccordionChange = (panel) => (_, expand) => {
    console.log("panel", panel);
    console.log(expand)
    setExpanded(expand ? panel : false);
  };

  const handleSubMenuItem = (path, data) => {
    if(path.includes("https")) return window.open(path, "_blank")
    if (data) {
      window.location.href = path
      // location.reload()
    }
    else if (path) window.location.href = path
  };

  const activeRoute = (path) => (path === pathname ? 1 : 0); // common side bar content

  const sideBarContent = (
    <Scrollbar autoHide clickOnTrack={false}>
      <List
        sx={{
          height: "100%",
          padding: 2,
        }}
      >

        <Box mb={2}>
          <Button
            variant="contained"
            size="small"
            sx={{
              height: "38px",
              fontSize: 14.5,
              borderRadius: 2,
              width: "100%",
              borderRadius: 2,
              backgroundColor: "primary.darkBlue",
              "&:hover": {
                backgroundColor: "primary.darkBlueHover",
              },
              cursor: "pointer",
            }}
            onClick={() => {
              if(account) {
                setShow(true)
              } else {
                dispatch(Logout())
                setTimeout(() => {
                  window.location.href = '/'
                }, 10)
              }
            }}
          >
            <ButtonText>{account ? "로그아웃" : "로그인"}</ButtonText>
          </Button>
        </Box>

        <Divider
          sx={{
            borderColor: "#E1E1E1",
            my: 2,
          }}
        />
        {account?.type == "지사" ? sideMenuList2.map((item, index) => {
          
          if (item.children && item.children.length > 0) {
            return (
              <AccordionMenu
                key={index}
                title={item.title}
                path={item.path}
                expandedItem={expanded}
                handleChange={handleAccordionChange}
                accordionHeader={
                  <FlexBox alignItems="center" gap={1}>
                    <ListItemButton
                      key={index}
                      to={item.path}
                      sx={{
                        pl: 4,
                        "&:hover": {
                          backgroundColor: "initial",
                        },
                      }}
                    >
                      <item.Icon
                        sx={{
                          fontSize: 20,
                        }}
                      />
                      <H6 lineHeight={1} fontWeight={500} ml={1}>
                        {t(item.title)}
                      </H6>
                    </ListItemButton>
                  </FlexBox>
                }
              >
                {item.children.map((menuItem, key) => {
                  if (!menuItem.subChildren) {
                    if (menuItem.name === "커뮤니티") {
                      return (
                        <>
                          {/* SaaS menu with dropdown behavior */}
                          <SubMenuItem
                            active={activeRoute("/dashboard/saas")}
                            onClick={() =>
                              handleSaasMenuClick("/dashboard/saas")
                            }
                          >
                            <Dot />
                            <H6 sx={{ color: "#151515" }}>커뮤니티</H6>
                            <Box onClick={handleSaasArrowClick}>
                              <ArrowForwardIosSharpIcon
                                onClick={handleSaasArrowClick}
                                sx={{
                                  top: 14,
                                  position: "absolute",
                                  right: 18,
                                  fontSize: "0.9rem",
                                  color: "#5C5E64",
                                  transform: saasMenuOpen
                                    ? "rotate(90deg)"
                                    : "none",
                                }}
                              />
                            </Box>
                          </SubMenuItem>

                          {/* Dropdown content */}
                          <Collapse
                            in={saasMenuOpen}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {/* Render submenu items */}
                              {saasMenuItems.map((item, index) => (
                                <ListItemButton
                                  key={index}
                                  // component={Link}
                                  to={item.path}
                                  sx={{ pl: 4, borderRadius: 2 }} // Adjust the indentation as needed
                                >
                                  <H6 sx={{ fontWeight: 500 }} color={"black"}>{item.name}</H6>
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      );
                    }
                    return (
                      <SubMenuItem
                        key={key}
                        active={activeRoute(menuItem.path)}
                        onClick={() => handleSubMenuItem(menuItem.path)}
                        ml={1.5}
                      >
                        <Dot />
                        <H6 color="text.primary">{t(menuItem.name)}</H6>
                      </SubMenuItem>
                    );
                  }
                })}
              </AccordionMenu>
            );
          }

          return (
            <StyledListItemButton
              // key={index}
              disableRipple
              // active={item.path === pathname ? 1 : 0}
              onClick={() => {
                setExpanded(false);
                handleSubMenuItem(item.path);
              }}
            >
              <item.Icon
                sx={{
                  color: "text.secondary",
                  fontSize: 20,
                }}
              />
              <H6 ml={1} lineHeight={1} fontWeight={500}>
                {t(item.title)}
              </H6>
            </StyledListItemButton>
          );
        }) : null}
        {(account?.type == "본사" || account?.type == "마스터") ? adminMenuSidebar.map((item, index) => {
          
          if (item.children && item.children.length > 0) {
            return (
              <AccordionMenu
                key={index}
                title={item.title}
                path={item.path}
                expandedItem={expanded}
                handleChange={handleAccordionChange}
                accordionHeader={
                  <FlexBox alignItems="center" gap={1}>
                    <ListItemButton
                      key={index}
                      to={item.path}
                      sx={{
                        pl: 4,
                        "&:hover": {
                          backgroundColor: "initial",
                        },
                      }}
                    >
                      <item.Icon
                        sx={{
                          fontSize: 20,
                        }}
                      />
                      <H6 lineHeight={1} fontWeight={500} ml={1}>
                        {t(item.title)}
                      </H6>
                    </ListItemButton>
                  </FlexBox>
                }
              >
                {item.children.map((menuItem, key) => {
                  if (!menuItem.subChildren) {
                    if (menuItem.name === "커뮤니티") {
                      return (
                        <>
                          {/* SaaS menu with dropdown behavior */}
                          <SubMenuItem
                            active={activeRoute("/dashboard/saas")}
                            onClick={() =>
                              handleSaasMenuClick("/dashboard/saas")
                            }
                          >
                            <Dot />
                            <H6 sx={{ color: "#151515" }}>커뮤니티</H6>
                            <Box onClick={handleSaasArrowClick}>
                              <ArrowForwardIosSharpIcon
                                onClick={handleSaasArrowClick}
                                sx={{
                                  top: 14,
                                  position: "absolute",
                                  right: 18,
                                  fontSize: "0.9rem",
                                  color: "#5C5E64",
                                  transform: saasMenuOpen
                                    ? "rotate(90deg)"
                                    : "none",
                                }}
                              />
                            </Box>
                          </SubMenuItem>

                          {/* Dropdown content */}
                          <Collapse
                            in={saasMenuOpen}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {/* Render submenu items */}
                              {saasMenuItems.map((item, index) => (
                                <ListItemButton
                                  key={index}
                                  // component={Link}
                                  to={item.path}
                                  sx={{ pl: 4, borderRadius: 2 }} // Adjust the indentation as needed
                                >
                                  <H6 sx={{ fontWeight: 500 }} color={"black"}>{item.name}</H6>
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      );
                    }
                    return (
                      <SubMenuItem
                        key={key}
                        active={activeRoute(menuItem.path)}
                        onClick={() => handleSubMenuItem(menuItem.path)}
                        ml={1.5}
                      >
                        <Dot />
                        <H6 color="text.primary">{t(menuItem.name)}</H6>
                      </SubMenuItem>
                    );
                  }
                })}
              </AccordionMenu>
            );
          }

          return (
            <StyledListItemButton
              // key={index}
              disableRipple
              // active={item.path === pathname ? 1 : 0}
              onClick={() => {
                setExpanded(false);
                handleSubMenuItem(item.path);
              }}
            >
              <item.Icon
                sx={{
                  color: "text.secondary",
                  fontSize: 20,
                }}
              />
              <H6 ml={1} lineHeight={1} fontWeight={500}>
                {t(item.title)}
              </H6>
            </StyledListItemButton>
          );
        }) : null}
      </List>
    </Scrollbar>
  ); // for mobile device

  if (downMd) {
    return (
      <LayoutDrawer open={showMobileSideBar} onClose={closeMobileSideBar}>
        {sideBarContent}
      </LayoutDrawer>
    );
  }

  return (
    <>
    <CompleteModal title={"로그아웃 완료"} description={""} action={""} onAction={() => {
        dispatch(Logout())
        window.location.href = '/'
    }} showModal={show} setShowModal={setShow}/>
    <SidebarWrapper show={showSideBar ? 1 : 0}>{sideBarContent}</SidebarWrapper>
    </>
  );
};

export default DashboardSidebar;