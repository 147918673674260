import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
import LayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v1/NaverLayout";
import LayoutV3 from "layouts/layout-v1/wrtnLayout";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

const Excel2 = Loadable(lazy(() => import("./pages/excel/excel2")));
const DataTableV2 = Loadable(
  lazy(() => import("./pages/data-table/data-table-v2"))
); // invoice

const LoginV2 = Loadable(
  lazy(() => import("./pages/authentication/login-v2"))
);

const RegisterV2 = Loadable(
  lazy(() => import("./pages/authentication/register-v2"))
);



const Error = Loadable(lazy(() => import("./pages/404")));

const routes = () => {
  return [
    {
      path: "",
      element: <LayoutV3 />,
      children: dashboardRoutes,
    },
    {
      path: "manager",
      element: <LayoutV1 />,
      children: [
        { path: "", element: <Excel2 /> },
      ],
    },
    {
      path: "admin",
      element: <LayoutV1 />,
      children: [
        { path: "user-list", element: <DataTableV2 /> },
        { path: "register", element: <RegisterV2 /> },
      ],
    },
    {
      path: "*",
      element: <Error />,
    }
  ];
};


const dashboardRoutes = [
  {
    path: "",
    element: <LoginV2 />,
  },
  {
    path: "/login",
    element: <LoginV2 />,
  },
];

export default routes;
